import i18n from '@/i18n/i18n'

export const getPolicyDetailsColumns = (lang) => {
  return [
    { label: i18n.t('productName'), prop: lang == 'en' ? 'productName' : 'productNameLocal' },
    { label: i18n.t('payment.benefitPack'), prop: lang == 'en' ? 'benefitPackage' : 'benefitPackageLocal' },
    { label: i18n.t('payment.policyHolder'), prop: 'policyHolderName' },
    { label: i18n.t('payment.premiumBenificiary'), prop: lang == 'en' ? 'premiumBeneficiary' : 'premiumBeneficiaryLocal' }
  ]
}

export const getExpiryDetailsColumns = (lang) => {
  return [
    { label: i18n.t('productName'), prop: lang == 'en' ? 'productName' : 'productNameLocal' },
    { label: i18n.t('payment.benefitPack'), prop: lang == 'en' ? 'benefitPackage' : 'benefitPackageLocal' }
  ]
}

export const paymentDetailsColumns = (lang) => {
  return [
    { label: i18n.t('paymentDue'), prop: 'paymentDueDate', formater: 'date' }
  ]
}

